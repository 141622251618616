import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { parseISO, format } from 'date-fns'
import { de } from 'date-fns/locale'
import copy from 'copy-to-clipboard'

import { getContactImage } from '@services/contactrequestservice'
import { deleteContactRequest } from '@services/contactrequestservice'

import { Typography, Box, Card, CardContent, CardActions } from '@mui/material'

import Copy from '@objects/copy'
import ContextMenu from '@objects/contextmenu/'
import styled from '@emotion/styled'

const InfoHeader = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.primary.contrastText,
}))

const Info = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.text.primary,
}))

const Img = styled('img')({
  maxWidth: '200px',
})

function ContactRequestCard({
  sxProp,
  onActionClick,
  id,
  url,
  description,
  email,
  timestamp,
  hasFile,
}) {
  const [image, setImage] = useState(null)

  const ContextMenuCommonProps = {
    mailto: `subject=&body=${description
      ?.replace(/&nbsp;/gi, ' ')
      .replace(/<\/?[^>]+>/gi, '')}`,
    onCopy: onCopyClick,
    onDelete: onDeleteClick,
  }

  function formatDate(date, formatStr) {
    return format(parseISO(date), formatStr, {
      locale: de,
    })
  }

  useEffect(() => {
    if (hasFile) {
      getContactImage(id).then(async (res) => {
        setImage(
          `data:${res.headers['content-type']};base64,${btoa(
            String.fromCharCode(...new Uint8Array(res.data))
          )}`
        )
      })
    }
  }, [hasFile])

  function onCopyClick() {
    copy(description, { format: 'text/html' })
  }

  function onDeleteClick() {
    deleteContactRequest(id).then((res) => {
      if (onActionClick) onActionClick()
    })
  }

  return (
    <Box position="relative" sx={sxProp}>
      <Card
        sx={{
          mt: 10,
          width: '100%',
          '&.comment': {
            borderRadius: 0,
          },
        }}
        elevation={3}
      >
        <Box
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            p: 3,
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <InfoHeader>{email}</InfoHeader>
              <a href={url} target="_blank" rel="noreferrer">
                <InfoHeader>{url}</InfoHeader>
              </a>
            </Box>
            <InfoHeader>{formatDate(timestamp, 'dd.MM.yyyy HH:mm')}</InfoHeader>
          </Box>
        </Box>
        <CardContent>
          <Copy html={description} />
          {image && (
            <Box sx={{ mt: 5 }}>
              <Info>Auf Bild klicken, um es im neuen Tab zu öffnen:</Info>
              <a
                href={`${process.env.GATSBY_CONTACTSERVICE_URL}api/contactadmin/image/${id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Img src={image} alt="" />
              </a>
            </Box>
          )}
        </CardContent>
        <CardActions sx={{ p: 3, justifyContent: 'flex-end' }}>
          <ContextMenu {...ContextMenuCommonProps} />
        </CardActions>
      </Card>
    </Box>
  )
}

ContactRequestCard.propTypes = {
  sxProp: PropTypes.object,
  onActionClick: PropTypes.func,
  id: PropTypes.number,
  email: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  timestamp: PropTypes.string,
  hasFile: PropTypes.bool,
}

export default ContactRequestCard
