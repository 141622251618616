import apiClient from './basicapi'

function getContactRequests() {
  return apiClient.get('contactadmin').then((res) => res.data)
}

function getContactImage(id) {
  return apiClient.get(`contactadmin/image/${id}`, {
    responseType: 'arraybuffer',
  })
}

function deleteContactRequest(id) {
  return apiClient.delete(`contactadmin/${id}`)
}

export { getContactRequests, getContactImage, deleteContactRequest }
